<template>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<v-container fluid>
  <v-simple-table v-if=enable>
    <template v-slot:default>
      <tbody>
        <tr
          v-for="item in profile"
          :key="item.name"
        >
          <th>{{ item.label }}</th>
          <td class="text-right">{{ item.name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <PreloaderComponent :enable=enable></PreloaderComponent>
</v-container>
</div>


</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'AccountView',
  methods: {
    load: function(){
      var self = this;
      var authentication = JSON.parse(localStorage.authentication)
      this.$axios.get("/businesses?include=stores,devices,renewals&prefix="+authentication.schema)
      .then(function (response) {
        self.business = response.data[0]
        var total = self.getTotal(response.data[0].devices)
        self.profile = [
          {label: "ENTREPRISE", name: response.data[0].name, key:"name"},
          {label: "TELEPHONE", name: response.data[0].phone, key:"phone"},
          {label: "E-MAIL", name: response.data[0].email, key:"email"},
          {label: "ADRESSE", name: response.data[0].address, key:"address"},
          {label: "PERSONNE DE CONTACT", name: response.data[0].contact_full_name, key:"contact_full_name"},
          {label: "IDENTIFICATION", name: response.data[0].contact_id, key:"contact_id"},
          {label: "TYPE IDENTIFICATION", name: response.data[0].contact_id_type, key:"contact_id_type"},
          {label: "APPAREILS", name: response.data[0].devices.length, key:"device_total"},
          {label: "MENSUALITE", name: total, key:"monthly_amount"}
        ]
        self.enable = true          
      })
    },
    getTotal: function(devices){
      var total = 0
      for (var i = devices.length - 1; i >= 0; i--) {
        if(devices[i].status == 1){
          total = total + parseFloat(devices[i]['amount'])
        }
      }
      return total.toFixed(2)+" HTG"
    },
  },
  mounted(){
    this.load()
  },
  data: () => ({
      items: [
        {
          text: 'COMPTE',
          disabled: false,
          href: '/',
        }
      ],
      fluid: true,
      business_id: 0,
      loading: false,
      enable: false,
      id_types: [{name:'CIN'}, {name:'NIF'}, {name:'PASSPORT_NUMBER'}, {name:'DRIVER_LICENSE'}],
      business: [],
      profile: [],
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .v-data-table__wrapper{
    border: 2px solid #f3f3f3;
  }
</style>